import { INavLink, INavLinkGroup } from '@fluentui/react'
import { PRODUCT_ENV } from 'app/config/Consts'
import { ProductEnvTypes } from 'app/config/productEnv.enum'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { analyzerLinks } from '../../analyzer/sidebarLinks'
import { ModuleType } from '../../pages/organization-details/modules/module-form/types'
import { useExtendedPatchManagementFeature } from '../../permissions/ExtendedPatchManagement.featureFlag'
import { FEATURE_NAME, FEATURE_NAMES } from '../../permissions/FeatureName.enum'
import { ODIN_REPORTS_ROOT_PATH } from 'app/pages/odin-reports/odin-reports-routes'
import { FeaturitFlag } from '../../permissions/authorizer/FeatureFlags'

interface FeaturedINavLink extends INavLink {
  feature?: FEATURE_NAME
  isPreview?: boolean
  featureFlag?: FeaturitFlag
}

export interface FeaturedINavLinkGroup extends INavLinkGroup {
  links: Array<FeaturedINavLink>
  isPreview?: boolean
}

export const navLinkGroups = (): FeaturedINavLinkGroup[] => {
  const { t } = useTranslation()
  const { selectedOrganizationId, selectedOrganization } = useOrganizationSelection()
  const navigate = useNavigate()
  const advancedMenuEnabled = localStorage.getItem('advancedMenuEnabled') === 'true'

  const groups: FeaturedINavLinkGroup[] = [
    {
      name: '',
      links: [
        {
          icon: 'Home',
          name: `${t('home:TITLE')}`,
          url: selectedOrganizationId ? `/organization/${selectedOrganizationId}/home` : '/home',
          key: 'home',
          feature: FEATURE_NAMES.HOME_READ,
        },
        {
          icon: 'Operations',
          name: `${t('efficiency_operations:TITLE')}`,
          url: `/organization/${selectedOrganizationId}/operations`,
          key: 'operations',
          feature: FEATURE_NAMES.OPERATIONS_READ,
        },
        {
          icon: 'TriggerAuto',
          name: t('flows:TITLE'),
          url: `/organization/${selectedOrganizationId}/flows`,
          key: 'flows',
          feature: FEATURE_NAMES.FLOWS_READ,
          isPreview: true,
        },
        {
          icon: 'TableGroup',
          name: t('odin_reports:TITLE'),
          url: `/organization/${selectedOrganizationId}/${ODIN_REPORTS_ROOT_PATH}`,
          key: 'odin-reports',
          feature: FEATURE_NAMES.ODIN_REPORTS_READ,
          featureFlag: FeaturitFlag.ODIN_REPORTS,
          isPreview: true,
        },
      ],
    },
  ]
  const addTenantGroup = (): void => {
    groups.push({
      name: `${t('organization_details:TENANTS_PIVOT')}`,
      groupData: {
        icon: 'RecruitmentManagement',
      },
      onHeaderClick: () => {
        navigate(`/organization/${selectedOrganizationId}/tenants`)
      },
      collapseByDefault: true,
      links: [
        {
          icon: '',
          name: t('organization_details:TENANTS_PIVOT'),
          url: `/organization/${selectedOrganizationId}/tenants`,
          key: 'org-tenants',
          feature: FEATURE_NAMES.TENANT_READ,
        },
        {
          icon: '',
          name: t('organization_details:TENANTS_ACTIVATIONS.TITLE'),
          url: `/organization/${selectedOrganizationId}/tenants/activations`,
          key: 'org-tenants-activations',
          feature: FEATURE_NAMES.ACTIVATION_READ,
        },
      ],
    })
  }

  const addModulesGroups = (): void => {
    const analyzerModule = selectedOrganization?.modules?.find((module) => module.type === ModuleType.FlexxAnalyzer)
    const workspacesModule = selectedOrganization?.modules?.find((module) => module.type === ModuleType.FlexxWorkspaces)

    if (workspacesModule) {
      groups.push({
        name: `Workspaces`,
        groupData: {
          icon: 'ThisPC',
        },
        collapseByDefault: true,
        links: [
          {
            onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
              ev?.preventDefault()
              window.open(workspacesModule.url + item?.url, '_blank')
            },
            icon: 'ArrowUpRight8',
            name: 'Dashboard',
            key: 'workspaces-dashboard',
            url: '/Flexxible/default.aspx#ViewID=DashboardInfoContainer_DetailView',
          },
          {
            onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
              ev?.preventDefault()
              window.open(workspacesModule.url + item?.url, '_blank')
            },
            icon: 'ArrowUpRight8',
            name: 'Workspaces',
            url: '/Flexxible/default.aspx#ViewID=Workspace_ListView\n',
            key: 'workspaces-workspaces',
          },
          {
            onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
              ev?.preventDefault()
              window.open(workspacesModule.url + item?.url, '_blank')
            },
            icon: 'ArrowUpRight8',
            name: 'Sessions',
            url: '/Flexxible/default.aspx#ViewID=ADObjectVMSessionLight_ListView',
            key: 'workspaces-sessions',
          },
          {
            onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
              ev?.preventDefault()
              window.open(workspacesModule.url + item?.url, '_blank')
            },
            icon: 'ArrowUpRight8',
            name: 'Alerts',
            url: '/Flexxible/default.aspx#ViewID=Alert_ListView',
            key: 'workspaces-alerts',
          },
          {
            onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
              ev?.preventDefault()
              window.open(workspacesModule.url + item?.url, '_blank')
            },
            icon: 'ArrowUpRight8',
            name: 'Servers',
            url: '/Flexxible/default.aspx#ViewID=ADObjectVMServerLight_ListView',
            key: 'workspaces-servers',
          },
        ],
      })
    }

    if (analyzerModule) {
      groups.push({
        name: `Analyzer`,
        groupData: {
          icon: 'Diagnostic',
        },
        collapseByDefault: true,
        links: [
          {
            onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
              ev?.preventDefault()
              window.open(analyzerModule.url + item?.url, '_blank')
            },
            icon: 'ArrowUpRight8',
            name: 'App Catalog & Inventory',
            url: '/apps/app-catalog',
            key: 'analyzer-app-catalog-inventory',
          },
          {
            onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
              ev?.preventDefault()
              window.open(analyzerModule.url + item?.url, '_blank')
            },
            icon: 'ArrowUpRight8',
            name: 'Diagnosis',
            url: '/diagnosis',
            key: 'analyzer-diagnosis',
          },
          {
            onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
              ev?.preventDefault()
              window.open(analyzerModule.url + item?.url, '_blank')
            },
            icon: 'ArrowUpRight8',
            name: 'Green IT',
            url: '/green-it',
            key: 'analyzer-green-it',
          },
          {
            onClick: (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
              ev?.preventDefault()
              window.open(analyzerModule.url + item?.url, '_blank')
            },
            icon: 'ArrowUpRight8',
            name: 'User Experience',
            url: '/experience-monitoring/user-experience',
            key: 'analyzer-user-experience',
          },
        ],
      })
    }
  }

  const addMicroservicesGroup = (): void => {
    groups.push({
      name: `${t('microservices:TITLE')}`,
      groupData: {
        icon: 'CubeShape',
        isPreview: PRODUCT_ENV !== ProductEnvTypes.FXXONE,
      },
      onHeaderClick: () => {
        navigate(`/organization/${selectedOrganizationId}/microservices-enabled`)
      },
      collapseByDefault: true,
      links: [
        {
          name: t('microservices:ENABLED_MICROSERVICES.MENU_TITLE'),
          icon: 'InboxCheck',
          url: `/organization/${selectedOrganizationId}/microservices-enabled`,
          key: 'microservices-enabled',
          feature: FEATURE_NAMES.ENABLED_MICROSERVICE_READ,
        },
        {
          name: t('microservices:MARKETPLACE.TITLE'),
          icon: 'ProductCatalog',
          url: `/organization/${selectedOrganizationId}/microservices-marketplace`,
          key: 'microservices-marketplace',
          feature: FEATURE_NAMES.MICROSERVICES_READ,
        },
        {
          name: t('microservices:DESIGNER.TITLE'),
          icon: 'Design',
          url: `/organization/${selectedOrganizationId}/microservices-designer`,
          key: 'microservices-designer',
          feature: FEATURE_NAMES.MICROSERVICES_READ,
        },
        {
          name: t('microservices:AUDIT_LOG.TITLE'),
          icon: 'ComplianceAudit',
          url: `/organization/${selectedOrganizationId}/microservices-events`,
          key: 'microservices-events',
          feature: FEATURE_NAMES.MICROSERVICES_READ,
        },
      ],
    })
  }

  const addSettingsGroup = (): void => {
    groups.push({
      name: t('organizations:SIDEBAR_TITLE'),
      groupData: {
        icon: 'Settings',
      },
      onHeaderClick: () => {
        navigate(`/organization/${selectedOrganizationId}/information`)
      },
      collapseByDefault: true,
      links: [
        {
          icon: '',
          name: t('organization_details:INFORMATION_PIVOT'),
          url: `/organization/${selectedOrganizationId}/information`,
          key: 'org-information',
          feature: FEATURE_NAMES.ORGANIZATION_READ,
        },
        {
          icon: '',
          name: t('billing:TITLE'),
          url: `/organization/${selectedOrganizationId}/billing`,
          key: 'org-billing',
          feature: FEATURE_NAMES.BILLING_READ,
          featureFlag: FeaturitFlag.ODIN_BILLING,
        },
        {
          icon: '',
          name: t('organization_details:USERS_PIVOT'),
          url: `/organization/${selectedOrganizationId}/users`,
          key: 'org-users',
          feature: FEATURE_NAMES.USER_READ,
        },
        {
          icon: '',
          name: t('organization_details:ROLES_PIVOT'),
          url: `/organization/${selectedOrganizationId}/roles`,
          key: 'org-roles',
          feature: FEATURE_NAMES.ROLE_READ,
        },
        {
          icon: '',
          name: t('organization_details:PRODUCTS_PIVOT'),
          url: `/organization/${selectedOrganizationId}/products`,
          key: 'org-products',
          feature: FEATURE_NAMES.ENVIRONMENT_READ,
        },
        {
          icon: '',
          name: t('organization_details:MODULES_PIVOT'),
          url: `/organization/${selectedOrganizationId}/modules`,
          key: 'org-modules',
          feature: FEATURE_NAMES.MODULE_READ,
        },
        {
          icon: '',
          name: t('organization_details:INTEGRATIONS_PIVOT'),
          url: `/organization/${selectedOrganizationId}/integrations`,
          key: 'org-integrations',
          feature: FEATURE_NAMES.INTEGRATIONS_READ,
        },
        {
          icon: '',
          name: t('organization_details:REPORTING_GROUPS_PIVOT'),
          url: `/organization/${selectedOrganizationId}/reporting-groups`,
          key: 'org-reporting-groups',
          feature: FEATURE_NAMES.REPORTING_GROUP_READ,
        },

        {
          icon: '',
          name: t('organization_details:ELEGIBLE_PRODUCTS_PIVOT'),
          url: `/organization/${selectedOrganizationId}/elegible-products`,
          key: 'org-elegible-products',
          feature: FEATURE_NAMES.ELIGIBLE_PRODUCT_READ,
        },
        {
          icon: '',
          name: t('organization_details:POLICIES_PIVOT'),
          url: `/organization/${selectedOrganizationId}/policies`,
          key: 'org-policies',
          feature: FEATURE_NAMES.POLICY_READ,
        },
      ],
    })
  }

  const addPatchManagementGroup = (): void => {
    let links = [
      {
        icon: '',
        name: t('patch_management:SIDEBAR.SUMMARY'),
        url: `/organization/${selectedOrganizationId}/patch-management/summary`,
        key: 'org-patch-management-summary',
        feature: FEATURE_NAMES.PATCH_MANAGEMENT_READ,
      },
      {
        icon: '',
        name: 'Targets Dashboard',
        url: `/organization/${selectedOrganizationId}/patch-management/targets`,
        key: 'org-patch-management-targets-summary',
        feature: FEATURE_NAMES.PATCH_MANAGEMENT_READ,
      },
      {
        icon: '',
        name: t('patch_management:SIDEBAR.REPORTING_GROUPS'),
        url: `/organization/${selectedOrganizationId}/patch-management/reporting-groups`,
        key: 'org-patch-management-reporting-groups',
        feature: FEATURE_NAMES.PATCH_MANAGEMENT_READ,
      },
      {
        icon: '',
        name: 'Microsoft Patches',
        url: `/organization/${selectedOrganizationId}/patch-management/patches`,
        key: 'org-patch-management-patches',
        feature: FEATURE_NAMES.PATCH_MANAGEMENT_READ,
      },
      {
        icon: '',
        name: 'Security',
        url: `/organization/${selectedOrganizationId}/patch-management/security`,
        key: 'org-patch-management-security',
        feature: FEATURE_NAMES.PATCH_MANAGEMENT_READ,
      },
      {
        icon: '',
        name: t('patch_management:SIDEBAR.WINDOWS_PATCH_POLICIES'),
        url: `/organization/${selectedOrganizationId}/patch-management/windows-patch-policies/`,
        key: 'org-patch-management-windows-patch-policies',
        feature: FEATURE_NAMES.PATCH_MANAGEMENT_READ,
      },
      {
        icon: '',
        name: t('patch_management:SIDEBAR.LINUX_PATCH_POLICIES'),
        url: `/organization/${selectedOrganizationId}/patch-management/linux-patch-policies/`,
        key: 'org-patch-management-linux-patch-policies',
        feature: FEATURE_NAMES.PATCH_MANAGEMENT_READ,
      },
      {
        icon: '',
        name: t('patch_management:SIDEBAR.TARGETS'),
        url: `/organization/${selectedOrganizationId}/patch-management/patch-policy-targets/`,
        key: 'org-patch-management-patch-policy-targets',
        feature: FEATURE_NAMES.PATCH_MANAGEMENT_READ,
      },
    ]

    const advancedPatchManagementFeatureExtraLinkKeys = [
      'org-patch-management-targets-summary',
      'org-patch-management-patches',
      'org-patch-management-security',
      'org-patch-management-windows-patch-policies',
      'org-patch-management-linux-patch-policies',
    ]

    if (!useExtendedPatchManagementFeature()) {
      links = links.filter((link) => !advancedPatchManagementFeatureExtraLinkKeys.includes(link.key))
    }

    groups.push({
      name: t('patch_management:SIDEBAR.TITLE'),
      groupData: {
        icon: 'Trackers',
        isPreview: true,
      },
      onHeaderClick: () => {},
      collapseByDefault: true,
      links: links,
    })
  }

  const addWorkspacesGroup = (): void => {
    groups.push({
      name: t('workspace_groups:SIDEBAR.WORKSPACES'),
      groupData: {
        icon: 'PC1',
      },
      onHeaderClick: () => {
        navigate(`/organization/${selectedOrganizationId}/workspaces`)
      },
      collapseByDefault: true,
      links: [
        {
          icon: '',
          name: t('workspace_groups:SIDEBAR.WORKSPACES_V2'),
          url: `/organization/${selectedOrganizationId}/workspaces-v2`,
          key: 'new-workspaces',
          isPreview: true,
          feature: FEATURE_NAMES.WORKSPACES_V2_READ,
          featureFlag: FeaturitFlag.WORKSPACES_V2,
        },
        {
          icon: '',
          name: t('workspace_groups:SIDEBAR.WORKSPACES'),
          url: `/organization/${selectedOrganizationId}/workspaces`,
          key: 'workspaces',
          isPreview: true,
          feature: FEATURE_NAMES.WORKSPACES_READ,
        },
        {
          icon: '',
          name: t('workspace_groups:SIDEBAR.WORKSPACES_GROUPS'),
          url: `/organization/${selectedOrganizationId}/workspace-groups`,
          key: 'workspace-groups',
          feature: FEATURE_NAMES.WORKSPACE_GROUPS_READ,
        },
      ],
    })
  }

  const addMonitorGroup = (): void => {
    groups.push({
      name: 'Monitor',
      groupData: {
        icon: 'ShieldAlert',
      },
      onHeaderClick: () => {
        navigate(`/organization/${selectedOrganizationId}/active-alerts`)
      },
      collapseByDefault: true,
      links: [
        {
          icon: '',
          name: 'Active alerts',
          url: `/organization/${selectedOrganizationId}/active-alerts`,
          key: 'new-workspaces',
          isPreview: true,
          feature: FEATURE_NAMES.MONITOR_ALERTS_READ,
          featureFlag: FeaturitFlag.MONITOR_ALERTS,
        },
      ],
    })
  }

  addTenantGroup()

  addMonitorGroup()

  addWorkspacesGroup()

  addPatchManagementGroup()

  groups.push(analyzerLinks(t))

  if (advancedMenuEnabled) {
    addModulesGroups()
  }

  addMicroservicesGroup()

  addSettingsGroup()

  return groups
}
