import { useTranslation } from 'react-i18next'
import { useQueryParam } from 'use-query-params'
import backgroundImage from '../../assets/img/Login.jpg'
import LogoFlexxible from '../../assets/img/logo-negative.svg'
import { useDownloadWithMagicLink } from '../../hooks/reportingGroupMagicLinks/useDownloadWithMagicLink'

export const MagicLinkPage = () => {
  const { t } = useTranslation('organization_details')
  const [token] = useQueryParam<string>('key')

  const { isLoading, isSuccess, isError } = useDownloadWithMagicLink(token)

  return (
    <main className="login" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="login__wrapper">
        <section className="login__card">
          <img src={LogoFlexxible} width="306px" alt="logo" className="login__card__logo" />
          {isLoading && (
            <h1 className="login__card__title dots">{t('REPORTING_GROUPS.MAGIC_LINKS.DOWNLOAD.LOADING')}</h1>
          )}
          {isSuccess && <h1 className="login__card__title">{t('REPORTING_GROUPS.MAGIC_LINKS.DOWNLOAD.SUCCESS')}</h1>}
          {isError && <h1 className="login__card__title">{t('REPORTING_GROUPS.MAGIC_LINKS.DOWNLOAD.ERROR')}</h1>}
        </section>
        <div className="login__card-shadow" />
      </div>
      <section className="login__footer">
        <img src={LogoFlexxible} width="174px" className="login__footer__logo" alt="logo" />
      </section>
    </main>
  )
}
