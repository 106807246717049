import { FEATURE_NAME, FEATURE_NAMES } from '../FeatureName.enum'
import { RolesOnBusiness } from '../../models/user/RolesOnBusiness'
import { SelectableOrganization } from 'app/hooks/organization/useGetOrganizationsForSelection'
import { PRODUCT_ENV } from '../../config/Consts'
import { ProductEnvTypes } from '../../config/productEnv.enum'

const checkOnlyUserHasL1ReadHigher = (rolesOnBusiness: RolesOnBusiness) => {
  if (rolesOnBusiness.isPortalUser()) {
    return rolesOnBusiness.hasL1ReadHigher()
  }

  return true
}

const checkOnlyUserHasL2OrHigher = (rolesOnBusiness: RolesOnBusiness) => {
  if (rolesOnBusiness.isPortalUser()) {
    return rolesOnBusiness.hasL2OrHigher()
  }

  return true
}

const checkUserHasAnalyzerAccess = (rolesOnBusiness: RolesOnBusiness) => {
  return rolesOnBusiness.hasAnalyzerPermissionAccess() || rolesOnBusiness.hasAnalyzerPermissionAdmin()
}

const isNotDelegatedTenant = (rolesOnBusiness: RolesOnBusiness, selectedOrganization?: SelectableOrganization) => {
  if (!selectedOrganization) {
    return true
  }
  return !selectedOrganization.isDelegatedOrganization
}

const isFlexxibleOne = (): boolean => {
  return PRODUCT_ENV === ProductEnvTypes.FXXONE
}

export const authorizationExceptions: Partial<
  Record<FEATURE_NAME, (rolesOnBusiness: RolesOnBusiness, selectedOrganization?: SelectableOrganization) => boolean>
> = {
  [FEATURE_NAMES.ENVIRONMENT_AGENT_SETTINGS_READ]: isNotDelegatedTenant,
  [FEATURE_NAMES.ENABLED_MICROSERVICE_READ]: checkOnlyUserHasL1ReadHigher,
  [FEATURE_NAMES.FLOWS_CREATE]: checkOnlyUserHasL2OrHigher,
  [FEATURE_NAMES.FLOWS_DELETE]: checkOnlyUserHasL2OrHigher,
  [FEATURE_NAMES.FLOWS_READ]: checkOnlyUserHasL1ReadHigher,
  [FEATURE_NAMES.FLOWS_UPDATE]: checkOnlyUserHasL2OrHigher,
  [FEATURE_NAMES.MICROSERVICES_READ]: checkOnlyUserHasL1ReadHigher,
  [FEATURE_NAMES.OPERATIONS_READ]: checkOnlyUserHasL1ReadHigher,
  [FEATURE_NAMES.PATCH_MANAGEMENT_CREATE]: checkOnlyUserHasL2OrHigher,
  [FEATURE_NAMES.PATCH_MANAGEMENT_DELETE]: checkOnlyUserHasL2OrHigher,
  [FEATURE_NAMES.PATCH_MANAGEMENT_READ]: checkOnlyUserHasL1ReadHigher,
  [FEATURE_NAMES.PATCH_MANAGEMENT_UPDATE]: checkOnlyUserHasL2OrHigher,
  [FEATURE_NAMES.REPORTING_GROUP_MAGIC_LINK_CREATE]: isFlexxibleOne,
  [FEATURE_NAMES.REPORTING_GROUP_MAGIC_LINK_READ]: isFlexxibleOne,
  [FEATURE_NAMES.WORKSPACES_READ]: checkOnlyUserHasL1ReadHigher,
  [FEATURE_NAMES.WORKSPACE_GROUPS_READ]: checkOnlyUserHasL1ReadHigher,
  [FEATURE_NAMES.LICENSES_READ]: (rolesOnBusiness, selectedOrganization) =>
    checkUserHasAnalyzerAccess(rolesOnBusiness) && isNotDelegatedTenant(rolesOnBusiness, selectedOrganization),
  [FEATURE_NAMES.INSTALLED_APPS_READ]: isNotDelegatedTenant,
  [FEATURE_NAMES.ACTIVATION_READ]: isNotDelegatedTenant,
  [FEATURE_NAMES.TENANT_READ]: isNotDelegatedTenant,
  [FEATURE_NAMES.LICENSES_CREATE]: checkUserHasAnalyzerAccess,
  [FEATURE_NAMES.WORKSPACES_V2_READ]: checkOnlyUserHasL1ReadHigher,
  [FEATURE_NAMES.VIEW_AS_END_USER]: checkOnlyUserHasL1ReadHigher,
  [FEATURE_NAMES.BILLING_READ]: isFlexxibleOne,
}
