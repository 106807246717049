export enum FeaturitFlag {
  DELEGATED_TENANTS = 'DELEGATED_TENANTS',
  API_KEYS_V2 = 'API_KEYS_V2',
  POLICY_V2 = 'POLICY_V2',
  WORKSPACES_GROUPS_DETAILS_WORKSPACES_V2 = 'WORKSPACES_GROUPS_DETAILS_WORKSPACES_V2',
  ORGANIZATION_LIST_V2 = 'ORGANIZATION_LIST_V2',
  VIEW_NUMBER_OF_EXECUTIONS_IN_ODIN_REPORTS = 'VIEW_NUMBER_OF_EXECUTIONS_IN_ODIN_REPORTS',
  VIEW_PERMISSIONS_ACTION_ITEM_IN_ROLE_TABLE = 'VIEW_PERMISSIONS_ACTION_ITEM_IN_ROLE_TABLE',
  INSTALLED_APPS = 'INSTALLED_APPS',
  LICENSES = 'LICENSES',
  PATCH_POLICY_TARGETS_V2 = 'PATCH_POLICY_TARGETS_V2',
  WORKSPACES_V2 = 'WORKSPACES_V2',
  ODIN_REPORTS = 'ODIN_REPORTS',
  WORKSPACE_GROUP_SCHEDULE = 'WORKSPACE_GROUP_SCHEDULE',
  WORKSPACE_GROUP_SYNC = 'WORKSPACE_GROUP_SYNC',
  VIEW_AS_END_USER = 'VIEW_AS_END_USER',
  
  // BILLING FLAGS
  ODIN_BILLING = 'ODIN_BILLING',
  ODIN_BILLING_SUBSCRIPTION_CREATE = 'ODIN_BILLING_SUBSCRIPTION_CREATE',
  ODIN_BILLING_TENANT_DETAIL_PERIOD_NAVIGATION = 'ODIN_BILLING_TENANT_DETAIL_PERIOD_NAVIGATION',
  ODIN_BILLING_INVOICE_PERIOD_NAVIGATION = 'ODIN_BILLING_INVOICE_PERIOD_NAVIGATION',
  // END BILLING FLAGS
  
  REPORTING_GROUP_MAGIC_LINKS = 'REPORTING_GROUP_MAGIC_LINKS',
  USERS_SHOW_CREATED_BY_UPDATED_BY_COLUMNS = 'USERS_SHOW_CREATED_BY_UPDATED_BY_COLUMNS',
  MONITOR_ALERTS = 'MONITOR_ALERTS',
  WORKSPACE_FOR_WOL_SCHEDULED = 'WORKSPACE_FOR_WOL_SCHEDULED',
  PROVISION_INTEL_VPRO_FOR_ORGANIZATION = 'PROVISION_INTEL_VPRO_FOR_ORGANIZATION'
}
