import { LoadingSpinner } from '@flexxibleit/flexxible-ui'
import { FC, useEffect } from 'react'
import { useRouteError } from 'react-router-dom'
import * as Sentry from '@sentry/react'

export const NewVersionPage: FC = () => {
  const error = useRouteError()

  useEffect(() => {
    const reportErrorAndReload = async () => {
      if (error) {
        try {
          Sentry.captureException(error, {
            tags: { routeError: true },
            extra: { message: 'auto-reload' },
          })

          await Sentry.flush(2000)
        } catch (err) {
          console.error('Error reporting to Sentry:', err)
        } finally {
          window.location.reload()
        }
      }
    }

    reportErrorAndReload()
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        gap: '1rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <LoadingSpinner />
      </div>
    </div>
  )
}
