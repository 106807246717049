import { useQuery } from '@tanstack/react-query'
import { saveAs } from 'file-saver'
import { GRAPH_BASE_URL } from '../../config/Consts'

export const useDownloadWithMagicLink = (token: string) => {
  const url = GRAPH_BASE_URL.replace(/\/api$/, '/') + `reporting-groups/magic-link/${token}`

  return useQuery(
    ['downloadWithMagicLink', token],
    async () => {
      return fetch(url).then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const contentDisposition = response.headers.get('Content-Disposition')

        const parts = contentDisposition!.split(';')
        const filename = parts[1].split('=')[1].replace(/['"]/g, '')

        return Promise.all([filename, response.blob()])
      })
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      enabled: !!token,
      retry: false,
      onSuccess: ([filename, blob]) => {
        saveAs(blob, filename)
      },
    }
  )
}
