import * as Sentry from '@sentry/react'
import { ErrorBoundary } from '@sentry/react'
import App from 'App'
import { RequireAuth } from 'app/authentication/RequireAuth'
import 'app/components/content-header/content-header.scss'
import { ErrorPage } from 'app/components/error-boundary/ErrorPage'
import { GlobalProvider } from 'app/globals/context/GlobalContext'
import { LoginPage } from 'app/pages/login/Login.page'
import NotFound from 'app/pages/not-found/not-found.page'
import { organizationsRoutes } from 'app/pages/organization-details/organizations-routes'
import { productsRoutes } from 'app/pages/products/product-routes'
import { wrokspaceGroupsRoutes } from 'app/pages/workspace-groups/ws-groups-routes'
import { createBrowserRouter } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { analyzerRoutes } from '../analyzer/routes'
import { FeedbackMessageProvider } from '../context/feedback-message/FeedbackMessageContext'
import { KeepQueryParams } from './KeepQueryParams'
import { NavigateToMainPage } from './NavigateToMainPage'
import { NewVersionPage } from 'app/components/error-boundary/NewVersionPage'
import { MagicLinkPage } from '../pages/magic-link/MagicLink.page'

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)
export const Router = sentryCreateBrowserRouter([
  {
    path: '/magic-link',
    errorElement: <NewVersionPage />,
    element: (
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <GlobalProvider>
          <MagicLinkPage />
        </GlobalProvider>
      </QueryParamProvider>
    ),
  },
  {
    path: '/login',
    element: (
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <GlobalProvider>
          <LoginPage />
        </GlobalProvider>
      </QueryParamProvider>
    ),
  },
  {
    path: '/',
    errorElement: <NewVersionPage />,
    element: (
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <GlobalProvider>
          <ErrorBoundary
            fallback={({ error, componentStack, resetError }) => {
              return <ErrorPage error={error} resetError={resetError} />
            }}
          >
            <RequireAuth>
              <FeedbackMessageProvider>
                <App />
                <KeepQueryParams />
              </FeedbackMessageProvider>
            </RequireAuth>
          </ErrorBoundary>
        </GlobalProvider>
      </QueryParamProvider>
    ),
    children: [
      {
        index: true,
        element: (
          <RequireAuth>
            <NavigateToMainPage />
          </RequireAuth>
        ),
      },
      { path: '/home', element: <NavigateToMainPage /> },
      {
        path: '/organizations',
        lazy: () => import(/* webpackPrefetch: true */ 'app/modules/portal/pages/organizations.page'),
      },
      {
        path: '/logins',
        lazy: () =>
          import(
            /* webpackPrefetch: true */ 'app/modules/portal/features/user-login-list/versions/v2/ListUserLoginsV2'
          ),
      },
      {
        path: '/settings',
        lazy: () => import(/* webpackPrefetch: true */ 'app/pages/user-settings/UserSettings.page'),
      },
      {
        path: '/flexxbuild-operations-logs',
        lazy: () => import(/* webpackPrefetch: true */ 'app/modules/portal/pages/FlexxBuildOperationsLogPage'),
      },
      ...productsRoutes,
      ...organizationsRoutes,
      ...wrokspaceGroupsRoutes,
      ...analyzerRoutes,
    ],
  },
  { path: '*', element: <NotFound /> },
])
