import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'
import { Business } from '../../query-client/types'

const businessByIdQuery = gql`
  query ($id: ID!) {
    getBusinessById(id: $id) {
      _id
      name
      country
      email
      type
      description
      language
      industry
      lastReport
      created_at
      active
      serviceNowCompanyId
      isTrial
      isDelegatedOrganization
      activeDirectoryCredentials {
        clientId
        tenantId
        isClientSecretSet
        extendCredentials
        allDelegatedTenants
        delegatedTenants
      }
      partner {
        _id
        name
        type
      }
      defaultPolicy {
        _id
        name
      }
      users {
        _id
        surname
        name
        email
        userRole {
          _id
          name
        }
        language
        department
      }
      productConfigs {
        _id
        created_at
        environment
        lastReportDate
        license
        active
        baseline
        region
        serviceNowRelatedEnvironment
        product {
          _id
          name
          active
          fixedBaseline
          uniqueCode
        }
        agentSettings {
          autoUpdateAgents
          resourcesReportRecurrence
          profileStorageReportRecurrence
          syncBrokerRecurrence
          detectNewCitrixSubscriptions
          collectEventLogs
          eventLogIds
          eventLogsRecurrence
          collectDisks
          collectServices
          collectPnPEvents
          collectPublicIP
          anyDeskSystemActionsRole
          remoteSupport
        }
      }
      modules {
        _id
        type
        name
        tag
        url
        visibility
      }
      tenants {
        _id
        created_at
        name
        region
        organizationRelated {
          _id
          type
        }
        product {
          _id
          name
          active
        }
        policy {
          _id
          name
        }
      }
      elegibleProducts {
        elegibleProduct {
          _id
          name
        }
        deploymentRegions
      }
      policies {
        _id
        name
        description
        product
        created_at
      }
      reportingGroups {
        _id
        name
        fishingPattern
        policyReportingGroup
        productConfig {
          _id
          environment
        }
        patchPolicyTargetId
        agentSettings {
          autoUpdateAgents
          collectEventLogs
          eventLogIds
          eventLogsRecurrence
          collectDisks
          collectServices
          collectPnPEvents
          collectPublicIP
          remoteSupport
          anyDeskSystemActionsRole
          collectDeviceLocation
        }
      }
      policy {
        _id
        name
      }
      uniqueCode
    }
  }
`

function getBusinessById(id: string): Promise<Business> {
  return graphClient.request(businessByIdQuery, { id }).then((r) => r.getBusinessById)
}

export default function (businessId: string) {
  return useQuery(['getBusinessById', businessId], () => getBusinessById(businessId), {
    refetchOnWindowFocus: false,
    enabled: !!businessId,
  })
}
