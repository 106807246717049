import type { RouteObject } from 'react-router-dom'

export const ODIN_REPORTS_ROOT_PATH = 'odin-reports'
export const ODIN_REPORTS_EXECUTIONS_PATH = ':id/executions'
export const ODIN_REPORTS_EXECUTION_DETAIL_PATH = `${ODIN_REPORTS_EXECUTIONS_PATH}/:id`

const ODIN_REPORTS_PATH = `/organization/:organizationId/${ODIN_REPORTS_ROOT_PATH}`

export const getOdinReportsPath = (organizationId: string) =>
  ODIN_REPORTS_PATH.replace(':organizationId', organizationId)

export const getOdinReportExecutionsPath = (organizationId: string, odinReportId: string) =>
  `${getOdinReportsPath(organizationId)}/${odinReportId}/executions`

export const getOdinReportExecutionDetailPath = (organizationId: string, odinReportId: string, executionId: string) =>
  `${getOdinReportExecutionsPath(organizationId, odinReportId)}/${executionId}`

export const odinReportsRoutes: RouteObject[] = [
  {
    path: ODIN_REPORTS_ROOT_PATH,
    children: [
      {
        index: true,
        lazy: () => import(/* webpackPrefetch: true */ 'app/pages/odin-reports/OdinReports.page'),
      },
      {
        path: ODIN_REPORTS_EXECUTIONS_PATH,
        lazy: () => import(/* webpackPrefetch: true */ 'app/pages/odin-reports/OdinReports.page'),
      },
      {
        path: ODIN_REPORTS_EXECUTION_DETAIL_PATH,
        lazy: () =>
          import(/* webpackPrefetch: true */ 'app/pages/odin-reports/list/executions/detail/OdinReportExecutionDetail'),
      },
    ],
  },
]
