import React, { ReactNode, useCallback, useMemo, useReducer } from 'react'
import { EventContext } from './EventContext'

// Definimos los tipos necesarios
export type SubscriberCallback = (payload?: any) => void
type SubscribersState = Record<string, SubscriberCallback[]>
type ActionType = 'subscribe' | 'unsubscribe'
type Action = { type: ActionType; event: string; callback: SubscriberCallback }

export type SubscribeFunction = (event: string, callback: SubscriberCallback) => void
export type UnsubscribeFunction = (event: string, callback: SubscriberCallback) => void
export type DispatchFunction = (event: string, payload?: any) => void

// Definimos las props para el componente
interface EventEmitterProps {
  children: ReactNode
}

export const EventEmitter: React.FC<EventEmitterProps> = ({ children }) => {
  const [subscribers, dispatch] = useReducer(
    (state: SubscribersState, action: Action): SubscribersState => {
      const { type, event } = action
      switch (type) {
        case 'subscribe': {
          const { callback } = action
          if (event in state) {
            if (state[event].includes(callback)) {
              return state
            }
            return { ...state, [event]: [...state[event], callback] }
          }
          return { ...state, [event]: [callback] }
        }

        case 'unsubscribe': {
          const { callback } = action
          if (event in state && state[event].includes(callback)) {
            return {
              ...state,
              [event]: state[event].filter((cb) => cb !== callback),
            }
          }
          return state
        }

        default:
          throw new Error('Unsupported action type')
      }
    },
    {} as SubscribersState, // Inicializamos el estado
    () => ({})
  )

  const subscribersRef = React.useRef<SubscribersState>({})

  subscribersRef.current = React.useMemo(() => subscribers, [subscribers])

  const subscribe = useCallback(
    (event: string, callback: SubscriberCallback) => {
      dispatch({ type: 'subscribe', event, callback })
    },
    [dispatch]
  )

  const unsubscribe = useCallback(
    (event: string, callback: SubscriberCallback) => {
      dispatch({ type: 'unsubscribe', event, callback })
    },
    [dispatch]
  )

  const dispatchEvent = useCallback(
    (event: string, payload?: any) => {
      if (event in subscribersRef?.current) {
        subscribersRef.current[event].forEach((cb) => cb(payload))
      }
    },
    [subscribersRef]
  )

  const eventPack = useMemo<[SubscribeFunction, UnsubscribeFunction, DispatchFunction]>(
    () => [subscribe, unsubscribe, dispatchEvent],
    [subscribe, unsubscribe, dispatchEvent]
  )

  return <EventContext.Provider value={eventPack}>{children}</EventContext.Provider>
}
